import {
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Select
} from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import {
  getTruelayerAccount,
  getTrueLayerAccountDirectDebits,
  getTrueLayerAccountStandingOrders
} from "../../api/projectmyfinance.api";
import { BlackOut } from "../../components/common/blackout";
import { AppState } from '../../models/models';
import { classes } from './payments.styles';

export function Payments() {
  const accounts = useSelector((state: AppState) => state.accounts);
  const accountData = useSelector((state: AppState) => state.accountData);
  const partnerAccounts = useSelector((state: AppState) => state.partnerAccounts);
  const directDebits = useSelector((state: AppState) => state.directDebits);
  const standingOrders = useSelector((state: AppState) => state.standingOrders);
  const [selectedAccount, setSelectedAccount] = useState<string>();

  const allAccounts = useMemo(() => {
    return [...(accounts || []), ...(partnerAccounts || [])].map(account => ({
      ...account,
    }));
  }, [accounts, partnerAccounts]);

  useEffect(() => {
    if (accounts) {
      accounts.forEach(account => {
        getTruelayerAccount(account.accountId);
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (partnerAccounts) {
      partnerAccounts.forEach(account => {
        getTruelayerAccount(account.accountId);
      });
    }
  }, [partnerAccounts]);

  useEffect(() => {
    if (accounts) {
      setSelectedAccount(accounts[0].accountId)
    } else if (partnerAccounts) {
      setSelectedAccount(partnerAccounts[0].accountId)
    }
  }, [accounts, partnerAccounts]);

  useEffect(() => {
    if (selectedAccount) {
      getTrueLayerAccountDirectDebits(selectedAccount);
      getTrueLayerAccountStandingOrders(selectedAccount);
    }
  }, [selectedAccount]);

  const handleAccountChange = (event: any) => {
    setSelectedAccount(event.target.value as string);
  };


  function getAccountName(accountId: string): string {
    const account = accountData.find(accountData => accountData.account_id === accountId);
    if (account && account.account_number.number) {
      return `${account.provider.display_name}: ${account.account_number.number}`;
    }
    return accountId;
  }

  return (
    <Container maxWidth="xl" sx={classes.container}>

      <Grid container rowSpacing={3} columnSpacing={3} spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {accountData.length > 0 && selectedAccount && <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Select Account</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={selectedAccount}
                defaultValue={selectedAccount}
                label="Select Account"
                onChange={handleAccountChange} variant={"outlined"}>
                {allAccounts.filter(account => {
                  const accountNumber = accountData.find(accountData =>
                    accountData.account_id === account.accountId)?.account_number.number;
                  return accountNumber !== null;
                }).map((account) => (
                  <MenuItem key={account.accountId} value={account.accountId}>
                    {getAccountName(account.accountId)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>}
        </Grid>
      </Grid>
      <Grid container rowSpacing={3} columnSpacing={3} spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2} rowSpacing={2} direction={'column'}>
            <Grid item>
              <Card>
                <CardHeader title="Direct Debits"></CardHeader>
                <CardContent>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {directDebits &&
                          directDebits
                            .filter(
                              (row) =>
                                row.status === 'Active' &&
                                row.previous_payment_amount !== 0
                            )
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">
                                  <BlackOut>
                                    <NumericFormat
                                      displayType="text"
                                      value={row.previous_payment_amount}
                                      prefix={
                                        row.currency
                                          ? getSymbolFromCurrency(row.currency)
                                          : getSymbolFromCurrency('GBP')
                                      }
                                      thousandSeparator={true}
                                    />
                                  </BlackOut>
                                </TableCell>
                                <TableCell align="right">
                                  {new Date(
                                    row.previous_payment_timestamp
                                  ).toLocaleDateString()}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                    Cancelled
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {directDebits &&
                          directDebits
                            .filter(
                              (row) =>
                                row.status === 'Inactive' &&
                                row.previous_payment_amount !== 0
                            )
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">
                                  <BlackOut>
                                    <NumericFormat
                                      displayType="text"
                                      value={row.previous_payment_amount}
                                      prefix={
                                        row.currency
                                          ? getSymbolFromCurrency(row.currency)
                                          : getSymbolFromCurrency('GBP')
                                      }
                                      thousandSeparator={true}
                                    />
                                  </BlackOut>
                                </TableCell>
                                <TableCell align="right">
                                  {new Date(
                                    row.previous_payment_timestamp
                                  ).toLocaleDateString()}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2} rowSpacing={2} direction={'column'}>

            <Grid item>
              <Card>
                <CardHeader title="Standing Orders"></CardHeader>
                <CardContent>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {standingOrders &&
                          standingOrders.map((row) => (
                            <TableRow
                              key={row.reference}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.reference}
                              </TableCell>
                              <TableCell align="right">
                                <BlackOut>
                                  <NumericFormat
                                    displayType="text"
                                    value={row.next_payment_amount}
                                    prefix={
                                      row.currency
                                        ? getSymbolFromCurrency(row.currency)
                                        : getSymbolFromCurrency('GBP')
                                    }
                                    thousandSeparator={true}
                                  />
                                </BlackOut>
                              </TableCell>
                              <TableCell align="right">
                                {row.frequency}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
